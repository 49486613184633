<template>


  <main class="kb-main" id="kb-support">
    <LxpMobileHeader title="학습인증 챌린지">
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
      <template v-slot:right>

      </template>
    </LxpMobileHeader>

    <div class="main-content main-component">
      <template v-if="tabMenu <= 0">

        <div class="intro-section" >

          <div class="kb-main" >
            <nav class="kb-nav-round-tabs"  style="padding:10px; margin-bottom: 0px;">
              <ul class="kb-nav-list">
                <li class="kb-nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu==1 }" @click="menu=1"><span class="kb-nav-text">파생상품</span></a>
                </li>
                <li class="nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu==2 }" @click="menu=2"><span class="kb-nav-text">SQLD</span></a>
                </li>
                <li class="nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu==3 }" @click="menu=3"><span class="kb-nav-text">신용분석사</span></a>
                </li>
                <li class="nav-item">
                  <a href="#" class="kb-nav-link" :class="{ 'is-active' : menu==4 }" @click="menu=4"><span class="kb-nav-text">펀드</span></a>
                </li>
              </ul>
            </nav>
            <div v-if="menu==1" class="main-content min-component" style="padding-bottom: 20px;">
              <MobileBoardReadOnly
                  :board-id="boardId[0]"
              />
            </div>
            <div v-if="menu==2" class="main-content min-component" style="padding-bottom: 20px;">
              <MobileBoardReadOnly
                  :board-id="boardId[1]"
              />
            </div>
            <div v-if="menu==3" class="main-content min-component" style="padding-bottom: 20px;">
              <MobileBoardReadOnly
                  :board-id="boardId[2]"
              />
            </div>
            <div v-if="menu==4" class="main-content min-component" style="padding-bottom: 20px;">
              <MobileBoardReadOnly
                  :board-id="boardId[3]"
              />
            </div>
          </div>


        </div>

      </template>

      <!--    <HrdCloudMap2 v-model="cloudMapModal"/>-->

      <section id="kb-hrdcloud" class="hrdcloud section" style="padding-bottom:0px;">
        <template v-for="(item, idx) in mainList" :key="idx">
          <article class="content-section"  v-if="idx === menu-1">
            <header class="section-header"><h3 class="title">{{ item.title }}</h3>
              <div class="header-sub">
                <router-link :to="{name: 'HrdCloud',  query:{sortTyCdDcd: '2073001', ...item.params }}" class="more-link">전체보기</router-link>
              </div>
            </header>
            <template v-if="item.items && item.items.length > 0">
              <HrdCloudRow :items="item.items" :show-put="true" :session="session"/>
            </template>
            <template v-else-if="(item.items && item.items.length === 0) && !item.isLoading">
              <div class="search-container">
                <div class="result-empty">
                  <img src="../../../../assets/lxp/images/common/img_empty.png" alt="">
                  <p class="text">콘텐츠가 없습니다.</p>
                </div>
              </div>

            </template>
            <template v-else-if="item.isLoading">
              <LoadingDiv v-model="item.isLoading" />
            </template>

          </article>
        </template>
      </section>


    </div>

  </main>
</template>

<script>
import {goBack} from '@/assets/js/modules/common/common';
import {useStore} from 'vuex';
import {computed, reactive, ref, watch} from 'vue';
import {
  closeToggles,
  getDcdOptions, pushQuery,
} from '@/assets/js/ui.init';
import {
  initProgressBar,
  timestampToDateFormat
} from '@/assets/js/util';
import { setMainItems} from '@/assets/js/modules/hrd/hrd-common';
// import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {
   ACT_GET_HRDCLOUD_LIST
} from '@/store/modules/prep/prep';
import {
  banerExpsrLocCdDcds,
} from '@/assets/js/modules/baner/common-baner';
import {Pagination} from "swiper";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import MobileBoardReadOnly from '@/components/board/mobile/BoardReadOnly'
import LoadingDiv from "@/components/common/LoadingDiv";
import HrdCloudRow from "@/components/hrdcloud/HrdCloudRow";
import {useRoute} from "vue-router/dist/vue-router";
const hrdLoading = ref(false);

export default {
  name: 'MobileLrnCertChall',
  components: {
    MobileBoardReadOnly,
    // Swiper,
    // SwiperSlide,
    LxpMobileHeader,
    LoadingDiv,
    HrdCloudRow,
  },
  setup() {
    initProgressBar();
    const boardId = reactive([
      'lrn-cert-drvt',
      'lrn-cert-sqld',
      'lrn-cert-cca',
      'lrn-cert-fund',
    ]);


    const route = useRoute();
    const store = useStore();
    const lochref = ref(location.href);
    const bannerCode = ref(banerExpsrLocCdDcds.bpcc);
    const cloudMapModal = ref(false);
    const items = ref([]);
    const mainList = ref([
      { title: '파생상품투자권유자문인력 콘텐츠', params: {category1:1 , category2: 13, category3:1876}, isLoading: true, items:[] },
      { title: 'SQLD 콘텐츠', params: {category1:1 , category2: 13, category3:1886}, isLoading: true, items:[] },
      { title: '신용분석사 콘텐츠', params: {category1:1 , category2: 13, category3:1877}, isLoading: true, items:[] },
      { title: '펀드투자권유자문인력 콘텐츠', params: {category1:1 , category2: 13,  category3:1874}, isLoading: true, items:[] },
    ]);
    const isLoading = ref(false);

    const session = computed(() => store.state.auth.session);
    const sortTypeDcd = computed(() => getDcdOptions('2073'));

    // 순서를 변경할때 getMainList의 idx값도 같이 바꿔줘야함

    // const currentTs = ref(new Date().getTime());
    const menu = ref(route.query.tab || 1);
    const myLikeTotalCnt = ref('0');

    const hrds = ref([]);
    const categories = ref([]);
    const tubePaging = reactive({
      pageNo: 1,
      pageSize: 4,
      totalCount: 0
    });

    const params = ref({});
    const tabMenu = ref(0);
    const toggles = reactive({
      sortTyCdDcd: false,
      lvl1Toggle: false
    });
    // 지금 나에게 필요한 콘텐츠, 따끈따끈 신규 콘텐츠, 나의 최근 학습 콘텐츠, 최근 직무 분야 인기 콘텐츠, 최근 직무외 분야 인기 콘텐츠
    const getMainList = async (idx) => {
      if(idx === '' || idx === undefined){
        idx = 0;
      }
      console.log(idx);
      let cont = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LIST}`, {...mainList.value[idx].params, ...tubePaging});
      setMainItems(mainList, cont, idx);
    };


    getMainList();

    watch(() => menu.value,  () => {

      getMainList(menu.value -1);
      pushQuery({tab : menu.value});
    })
    return {
      isLoading, session, sortTypeDcd, hrds,   cloudMapModal, lochref,
      tabMenu, params, categories,
      mainList,
      toggles, closeToggles,
      modules: [Pagination],
      items,
      goBack,
      menu,
      myLikeTotalCnt,
      // board_first,
      // board_second,
      timestampToDateFormat,
      hrdLoading,
      bannerCode,
      boardId,

    }
  }
};
</script>